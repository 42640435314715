import React, { useEffect, useState } from "react";

// import './Style.css'


import Modal from "../modal/Modal";
import { get, post } from "../../services/apiHandler";
import { useNavigate } from "react-router-dom";
import { Dna } from "react-loader-spinner";

export default function Form() {
  const nav = useNavigate()
  const [showModal, setShowModal] = useState(false);
  const handleOnClose = () => setShowModal(false);
  let [progressCount, setprogressCount] = useState(0);
  const [answersData, setAnswersData] = useState([]);
  const [subBtn, setsubBtn] = useState(false);
  const [ErrBtn, setErrBtn] = useState(false);
  let [opans, setopans] = useState({});
  const [pendindReport,setPendingReport]=useState(false)
  const [Score, setScore] = useState(null);
  // const [showModal, setShowModal] = useState(false)
  const [ErrMsg, setErrMsg] = useState("Please Tick All the Question");
  let [qno, setQueno] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const handleSignupClose = () => {
    setShowModal(false)
  }
  const handleChange = (question, category, answer, option_id) => {
    
    if (qno[answer] == 0) {
      qno[answer] = 1;
      setprogressCount(progressCount + 1);
    }
    let data = {
      question_category: category,
      question: question,
      answers: answer,
      option_id: option_id,
    };
    const index = answersData.findIndex(
      (item) => item.question == data.question
    );
    if (index !== -1) {
      answersData[index] = data;
    } else {
      answersData.push(data);
    }
  };

  const submitAnswers = () => {

   

    answersData.forEach(object => {
      delete object['answers'];
    });

    // axios
    //   .post(
    //     "https://staging.knotrite.in/backend/api/assesment",
    //     answersData,
    //     config
    //   )
    //   .then((res) => {
    //     alert("Report Submitted Successfully. Report id is : "+ res.data.report_id);
    //   })
    //   .catch((e) => {
    
    //     alert("Action Failed. Report id is : "+ e.response.data.report_id);

    //   });
    // /free_assessment
    if (localStorage.getItem("login") && JSON.parse(localStorage.getItem("login"))?.token) {
      post("api/assesment", answersData).then((res) => {
        
        // setErrMsg("Report Submitted Successfully. Report id is : "+ res.data.report_id);
        setScore(`Score:  ${res.data.report_id}`)
        setPendingReport(false)
      })
        .catch((e) => {
          
          setErrMsg("Action Failed. Report id is : " + e.response.data.report_id);
          setErrBtn(true)
        });
    }
    else {
      localStorage.setItem("assesment",JSON.stringify(answersData))
      post("api/free_assessment", answersData).then((res) => {
        
        // setErrMsg("Report Submitted Successfully. Report id is : "+ res.data.report_id);
        setScore(`Score is :  ${res.data.data.report_id}`)
        // setShowModal(true);
        // setErrBtn(true)
      })
        .catch((e) => {
          
          setErrMsg("Action Failed. Score is : " + e.response.data.report_id);
          setErrBtn(true)
        });
    }
  }


  const optionClass = [
    "form-check-input mt-10 form-check-input appearance-none rounded-full h-[40px] w-[40px] sm:h-[60px] sm:w-[60px] border-2 border-[#521350] bg-white checked:bg-[#521350]  focus:outline-none transition duration-200 sm:ml-0 ml-4 align-top bg-no-repeat bg-center bg-contain sm:mt-[40px] h-5 w-5 mt-10  2xl:rounded-full",
    "mt-4 form-check-input form-check-input appearance-none h-[30px] w-[30px] sm:h-[40px] sm:w-[40px]  rounded-full border-2 border-[#521350] bg-white checked:bg-[#521350] checked:border-[#521350]  2xl:rounded-full  focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain md:mt-4 sm:mt-4",
    "  h-[20px] w-[20px] sm:h-[30px] sm:w-[30px] mt-5 md:h-6 md:w-6 md:mt-5 sm:h-5 sm:w-5 sm:mt-5 form-check-input form-check-input appearance-none  rounded-full border-2 border-[#521350] bg-white checked:bg-[#521350]  2xl:rounded-full  focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain",
    "h-[10px] w-[10px] sm:h-[20px] sm:w-[20px]  mt-10 sm:mt-[50px] form-check-input form-check-input appearance-none  rounded-full border border-[#521350]  bg-white checked:bg-black md:h-4 md:w-4  2xl:rounded-full  focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain mb-2  ",
    "h-[20px] w-[20px] sm:h-[30px] sm:w-[30px] form-check-input form-check-input appearance-none  rounded-full border-2 border-[#521350] bg-white checked:bg-[#521350] checked:border-[#521350] 2xl:rounded-full  focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain md:mt-5 mt-5  ",
    "mt-4 form-check-input form-check-input appearance-none  rounded-full border-2 border-[#521350] bg-white checked:bg-[#521350] checked:border-[#521350]  2xl:rounded-full  focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain  md:mt-4  sm:mt-4  h-[30px] w-[30px] sm:h-[40px] sm:w-[40px]",
    "  mt-10 form-check-input form-check-input appearance-none  rounded-full   border-2 border-[#521350] bg-white checked:bg-[#521350] checked:border-[#521350] 2xl:rounded-full  focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain  h-[40px] w-[40px] sm:h-[60px] sm:w-[60px] sm:mt-[40px]",
  ];
  let options = (key, qId, qCatId, data) => {

    let option_arr = [];
    for (let i = 0; i < 7; i++) {
      option_arr.push(
        <div
          key={i}
          className="flex flex-col justify-center  items-center mr-2 sm:mr-4"
        >
          <div className="one flex flex-col items-center justify-center">

            <input
              type="radio"
              name={key}
              checked={opans[qId]==data.questions_options[i].id}
              onClick={(e) => {
                
                handleChange(qId, qCatId, e.target.name, data.questions_options[i].id)
                setopans({...opans,[qId]:data.questions_options[i].id})
              }
              }
              value={i}
              id={1}

              className={optionClass[i]}
            />

            <span className=" sm:w-[60px] translate-y-1 flex justify-center items-center">
              <span
                className={
                  i == 0
                    ? " text-[8px] font-[600] sm:text-[13px]s text-center  2xl:text-[13px]s md:text-sm sm:translate-x-0 translate-x-2 "
                    : " text-[8px] font-[600] sm:text-[13px]s text-center 2xl:text-[13px]s md:text-sm  "
                }
              >
                {i == 0 && "STRONGLY DISAGREE"}
                {i == 6 && "STRONGLY AGREE"}
                {i == 3 && "NEUTRAL"}
              </span>
            </span>
          </div>
        </div>
      );
    }
    return option_arr;
  };
  const [ques, setQues] = useState([]);

  const fetchData = () => {
    get(
      "api/get_questions/4"
    )
      .then((res) => {
        let questions = res.data.data;
        
        setQues(questions);
        
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [page, setPage] = useState(0);

  const FormTitles = ["1", "2"];



  const PageDisplay = () => {
    if (page === 0) {
      const OneArray = ques.slice(0, 9);

      return (
        <div className="App  ">
          <section className=" w-full  py-1 bg-white lg:py-2">
            <div className=" w-full  px-4 mx-auto sm:px-6 lg:px-8">
              <div className=" items-center flex-col flex justify-center">
                {OneArray?.map((item, key) => (
                  <div
                    className=" min-w-[100%] sm:min-w-[80%]  min-h-[200px] py-5 shadow-xl border boder-slate-300 px-5  
                            md:py-5 md:shadow-xl md:border 
                           
                           
                             sm:border sm:border-slate-300 sm:py-8  sm:mb-3
                            flex flex-col justify-center
                           lg:ml-0"
                    key={key}
                  >
                    <label className=" sm:text-sm 2xl:text-base">
                      <b className="text-[13px]ink-800">
                        {`${key + 1}. ${item.question}`}
                      </b>
                    </label>
                    <div className="flex justify-around">
                      {options(key, item.id, item.question_category, item)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      );
    } else if (page === 1) {
      const TwoArray = ques.slice(9, 18);

      return (
        <div className="App  ">
          <section className=" w-full  bg-white  ">
            <div className=" w-full  px-4 mx-auto sm:px-6 lg:px-8">
              <div className=" mt-1   items-center flex-col flex justify-center">
                {TwoArray?.map((item, key) => (
                  <div
                    className=" min-w-[90%]  sm:min-w-[80%] min-h-[200px] py-1 shadow-xl border boder-slate-300 px-5 ml-2 
                             md:py-5 md:shadow-xl md:border 
                             2xl:max-w-4xl   2xl:ml-1
                            
                              sm:border sm:border-slate-300  sm:mb-3
                             flex flex-col justify-center
                            lg:ml-0"
                    key={key + 9}
                  >
                    <label className=" sm:text-sm 2xl:text-base">
                      <b className="text-[13px]ink-800">
                        {`${key + 10}. ${item.question}`}
                      </b>
                    </label>
                    <div className="flex justify-around">
                      {options(key + 9, item.id, item.question_category, item)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      );
    } else if (page === 2) {
      const ThreeArray = ques.slice(18, 36);

      return (
        <div className="App  ">
          <section className=" w-full  py-10 bg-white sm:py-16 lg:py-24">
            <div className=" w-full  px-4 mx-auto sm:px-6 lg:px-8">
              <div className=" mt-12 sm:mt-16   items-center flex-col flex justify-center">
                {ThreeArray?.map((item, key) => (
                  <div
                    className=" min-w-[100%] sm:min-w-[80%] min-h-[200px] py-5 shadow-xl border boder-slate-300 px-5 ml-2 
                          md:py-5 md:shadow-xl md:border 
                          2xl:max-w-4xl   2xl:ml-1
                         
                           sm:border sm:border-slate-300 sm:py-8  sm:mb-3
                          flex flex-col justify-center
                         lg:ml-0"
                    key={key + 18}
                  >
                    <label className="sm:text-sm 2xl:text-base">
                      <b className="text-[13px]ink-800">
                        {`${key + 19}. ${item.question}`}
                      </b>
                    </label>
                    <div className="flex justify-around">
                      {options(key + 18, item.id, item.question_category)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      );
    } else {
      const FourArray = ques.slice(15, 18);

      return (
        <div className="App  ">
          <section className=" w-full  py-10 bg-white sm:py-16 lg:py-24">
            <div className=" w-full  px-4 mx-auto sm:px-6 lg:px-8">
              <div className=" mt-12 sm:mt-16   items-center flex-col flex justify-center">
                {FourArray?.map((item, key) => (
                  <div
                    className=" min-w-[100%] sm:min-w-[80%] min-h-[200px] py-5 shadow-xl border boder-slate-300 px-5 
                          md:py-5 md:shadow-xl md:border 
                          2xl:max-w-4xl   2xl:ml-1
                         
                           sm:border sm:border-slate-300 sm:py-8  sm:mb-3
                          flex flex-col justify-center
                         "
                    key={key + 15}
                  >
                    <label className=" sm:text-sm 2xl:text-base">
                      <b className="text-[13px]ink-800">
                        {`${key + 16}. ${item.question}`}
                      </b>
                    </label>
                    <div className="flex justify-around">
                      {options(key + 15, item.id, item.question_category)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      {ques.length === 0 ? <div className=" flex justify-center items-center ">
        <Dna
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        /></div> : <div
          id="progress"
          className="h-fit mt-12 text-black mb-1 flex  align-center flex-col "
        >
        <div className=" flex justify-center z-[2]  sticky top-[70px] md:top-[90px]">
          <div
            className="flex justify-center items-center
            w-[76%]  bg-[#f4d9f4] rounded py-2 iteams-center"
          >
            <div className="progressbar ">
              <div
                style={{
                  width:
                    progressCount === 0
                      ? "0%"
                      : progressCount === 1
                        ? "5.9%"
                        : progressCount === 2
                          ? "11.7%"
                          : progressCount === 3
                            ? "17.4%"
                            : progressCount === 4
                              ? "23.2%"
                              : progressCount === 5
                                ? "29.3%"
                                : progressCount === 6
                                  ? "35.1%"
                                  : progressCount === 7
                                    ? "40.9%"
                                    : progressCount === 8
                                      ? "46.7%"
                                      : progressCount === 9
                                        ? "52.9%"
                                        : progressCount === 10
                                          ? "58.7%"
                                          : progressCount === 11
                                            ? "64.5%"
                                            : progressCount === 12
                                              ? "70.5%"
                                              : progressCount === 13
                                                ? "76.5%"
                                                : progressCount === 14
                                                  ? "82.3%"
                                                  : progressCount === 14
                                                    ? "88.5%"
                                                    : progressCount === 15
                                                      ? "93%"
                                                      : progressCount === 16
                                                        ? "95%"
                                                        : "100%",
                }}
              ></div>
            </div>
            <h1 className="pr-2">{progressCount}/17</h1>
          </div>

        </div>
        <div className="justify-around mt-1 mb-1">{PageDisplay()}</div>
        <div className=" flex justify-evenly ">
          <button
            disabled={page === 0}
            onClick={() => {
              document.getElementById("go").click();
              setPage((currPage) => currPage - 1);
            }}
            className="bg-white text-slate-400 uppercase py-2 px-4 rounded-xl font-semibold cursor-pointer 
                 border-2 border-slate-300 hover:bg-slate-700 
                hover:text-white transition duration-200 ease-in-out"
          >
            Back
          </button>
          <a href="#progress" className="none" id="go"></a>
          {page <= 0 ? (
            <button
              disabled={page === FormTitles.length - 1}
              onClick={() => {
                if ((page + 1) * 9 > progressCount) {
                  setErrMsg("Please Tick All the Question")
                  setErrBtn(true)
                  return
                }
                document.getElementById("go").click();
                setPage((currPage) => currPage + 1);
              }}
              className="bg-[#480740] text-white uppercase py-2 px-4 rounded-xl 
                 font-semibold cursor-pointer sm:rounded-xl sm:text-white sm:py-2 sm:px-4  sm:border-2 sm:border-slate-300 sm:hover:bg-[#480740c6]
                  border-2 border-slate-300 hover:bg-[#480740c6]  sm:tranition sm:duration-200 sm:ease-in-out  
                hover:text-white transition duration-200 ease-in-out"
            >
              Next
            </button>
          ) : (
            <p
              onClick={() => {
                if (progressCount === 17) {
                  setsubBtn(true)
                  submitAnswers()
                }
                else {
                  setErrMsg("Please Tick All the Question")
                  setErrBtn(true)
                }
              }}
              className="bg-[#480740] text-white uppercase py-2 px-4 rounded-xl 
              font-semibold cursor-pointer sm:rounded-xl sm:text-white sm:py-2 sm:px-4  sm:border-2 sm:border-slate-300 
               border-2 border-slate-300  hover:bg-[#480740c6]  sm:tranition sm:duration-200 sm:ease-in-out  
             hover:text-white transition duration-200 ease-in-out"
            >
              submit
            </p>
          )}
        </div>
      </div>
      }
      <Modal onClose={handleOnClose} visible={showModal} />
      {subBtn && (
        <div class="fixed z-10 inset-0 overflow-y-auto  border-black">
          <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

            <div class="fixed inset-0 transition-opacity">
              <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div
              class={
                " borderbox inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border-2 border-solid"
              }

            >
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="float-right cursor-pointer " onClick={() => {
                  nav("/myaccount")
                  setsubBtn(false)
                }}>X</div>
                <div class="sm:flex sm:items-start">

                  <div class="mt-3  sm:mt-0 sm:ml-4 sm:text-left ">
                    <h3 class="text-lg leading-6 font-bold text-[#641662]">
                      Dear {JSON.parse(localStorage.getItem("login"))?JSON.parse(localStorage.getItem("login")).fullname:"user"},
                    </h3>
                    <br />
                    <p class="text-sm text-[#641662]">
                    You have successfully completed the free KnotRiteReadiness test. The score below indicates your current readiness for marriage or relationship.
                    </p>
                    <p class="text-sm my-2 text-[#641662] text-[15px]">{Score}</p>
                    <div >
                      {/* <div className="pl-2 bg-[#641662] p-2">
                        <p className="text-white"> KnotRiteReadiness Score : HIGH</p>
                      </div> */}
                      <div className="mt-3">
                        <p className="text-sm text-[#641662]">

                        For detailed assessment report describing your specific attributes, we request you to kindly visit my account page. You may have to  sign up or log in if not already logged in.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-center  ">
                <button
                  type="button"
                
                  onClick={() => {


                    if (JSON.parse((!localStorage.getItem("login"))||(localStorage.getItem("login"))&&JSON.parse(localStorage.getItem("login")).token== null)) {
                      // document.getElementById("navSignUp").click()
                      // setPendingReport(true)
                      // setShowModal(true)
                      // setsubBtn(false);
                      nav("/signup")
                    }
                    else {
                     nav("/myaccount")
                    }

                  }}
                  class="cursor-pointer borderbox mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#641662] text-base font-medium text-white hover:bg-[#4c104a] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {(JSON.parse((!localStorage.getItem("login"))||(localStorage.getItem("login"))&&JSON.parse(localStorage.getItem("login")).token==null))
                    ? "Sign Up"
                    : "My Account"}
                </button>

              </div>
            </div>
          </div>
        </div>
      )}

      {ErrBtn && (
        <div class="fixed z-10 inset-0 overflow-y-auto">
          <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity">
              <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div
              class={
                "inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              }
            >
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      class="h-6 w-6 text-green-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div class="mt-3  sm:mt-0 sm:ml-4 sm:text-left ">
                    <h3>{ErrMsg}</h3>


                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

                <button
                  type="button"
                  onClick={() => {
                    setErrBtn(false);
                  }}
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

      )}
      <Modal  submitForm={submitAnswers} onClose={handleSignupClose} pendingReport={pendindReport} onLogin={"handleLogin"} visible={showModal} />
      {/* <Modal onClose={handleSignupClose} visible={showModal} /> */}
    </React.Fragment>
  );
}
