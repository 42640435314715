import axios from "axios"
import { json } from "react-router-dom";

// const baseURL = 'https://staging.knotrite.in/backend/';
const baseURL = 'https://staging.knotrite.in/backend/';

const apiRequest = async (method, endpoint, data) => {
const token=JSON.parse(localStorage.getItem("login"))?.token;

    try {

    let config = {
      method:  method,
      maxBodyLength: Infinity,
      url:baseURL+endpoint,
      data:data,
      headers: { 
        'Accept': 'application/json', 
        'Authorization': 'Bearer '+token
      }
    };
 
    let result=await axios.request(config)
   
    return {
      response: true,
      // status: result.status,
      error: null,
      data: result.data,
    };
   
  } catch (error) {
    return {
      response: false,
      status: error.response.status,
      // error: error.response.data.errors.message,
      data: error.response.data,
    };
  }
};

export const get = (endpoint) => apiRequest('get', endpoint, null);
export const post = (endpoint, data) => apiRequest('post', endpoint, data);
export const put = (endpoint, data) => apiRequest('put', endpoint, data);
export const remove = (endpoint, data) => apiRequest('delete', endpoint, data);
