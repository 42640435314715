import { useNavigate } from "react-router-dom";

export default function Card(props) {
    const Nav=useNavigate()
  return (
    <>
      <div className="bg-white col-auto max-w-[300px] pb-4 min-w-[300px]  max-h-[308px] mx-[2%] my-3 border-[1px] border-gray-200 hover:border-transparent hover:shadow-2xl duration-200 rounded-3xl shadow-md overflow-hidden cursor-pointer" onClick={()=>Nav("/marriage-toolkit/"+props.url,{ state: {title:props.title,content:props.content,img:props.img,date:props.date,topic:props.topic,latestPost:props.latestPost,subHeading:props.subHeading} })}>
        <div className="img w-full max-h-[150px] min-h-[152px] flex justify-center items-center  overflow-hidden">
          <img  src={`https://staging.knotrite.in/backend/storage/${props.img}`} alt="" />
        </div>
        <div className="py-2 pb-4 px-4">
          <h1 className="font-[600] py-2 text-[15px] text-[#262626B8]" dangerouslySetInnerHTML={ {__html:props.title}}>
           
          </h1>
          <p className="font-[500] h-[50px] text-clip leading-4  text-[10px] text-[#1F1F1FC7]"  dangerouslySetInnerHTML={ {__html:props.content}}>
         
          </p>
        </div>
      </div>
    </>
  );
}
